import React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {DocTitle} from "../../common/DocTitle";
import * as actions from '../../../actions/user/userAction';
import {ActiveProducts} from "../../Dashboard/ActiveProducts";
import "./_style.scss";


class CustomerSwitcherPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.props.getUserCustomers();

        this.state = {
            selectedCustomer: "",
        };
    }

    handleTileClickedToSwitch = (selectedCustomerId) => {
        this.props.getCustomerUserProducts(selectedCustomerId);
        this.setState({ selectedCustomer: selectedCustomerId });
        this.props.updateSessionCustomer(selectedCustomerId);
    };


    renderCustomerTiles = () => {
            return (
                <div className="customer-selection-page main-content">
                <div className="customer-selection-title">Select your institution</div>
                <div className="customer-list-container">
                    {
                        this.props.customers.map(c => {
                            return (
                                <div key={c.customerId}
                                    className="customer-tile"
                                    onClick={() => this.handleTileClickedToSwitch(c.customerId)}
                                    data-automation={encodeURI(c.customerName)}>
                                    <div>{c.customerName}</div>
                                </div>
                            );
                        })
                    }
                </div>
                </div>
            );
    };

    renderProductTiles = () => {

        if (this.props.myProducts != null && this.props.myProducts.length === 1) {
            this.props.transferToProduct(this.props.myProducts[0]);
            return;
        }

        return (
            <div className="dashboard-page main-content">
                <div className="section-header">My Active Subscriptions</div>
                <ActiveProducts products={this.props.myProducts}  onClickHandler={this.props.transferToProduct} />
            </div>
        );
    };

    render() {
    return (
        <div className="content-panel">
            { (this.state.selectedCustomer === "") ? this.renderCustomerTiles() : this.renderProductTiles() }
            <DocTitle syncTo={"Ncontracts - Customer Selection Page"} />
        </div>
    );
}
}

CustomerSwitcherPage.propTypes = {
    customers: PropTypes.array,
    history: PropTypes.object,
    myProducts: PropTypes.array,
    transferToProduct: PropTypes.func,
    getCustomerUserProducts: PropTypes.func,
    getUserCustomers: PropTypes.func,
    updateSessionCustomer: PropTypes.func
};

CustomerSwitcherPage.defaultProps = {
    customers: []
};

function mapStateToProps(state) {
    return {
        customers: state.user.userCustomers,
        myProducts: state.user.activeProducts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserCustomers: () => dispatch(actions.getUserCustomers()),
        getCustomerUserProducts: (customerId) => dispatch(actions.getUserProducts(customerId)),
        transferToProduct: (product) => dispatch(actions.transfer(product.name)),
        updateSessionCustomer: (customerId) => dispatch(actions.updateSessionCustomer(customerId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSwitcherPage);
