import {combineReducers} from "redux";
import authReducer from "./auth/signInReducer";
import resetPasswordReducer from "./auth/resetPasswordReducer";
import userReducer from "./user/userReducer";
import topRailNavReducer from "./nav/topRailNavReducer";

const rootReducer = combineReducers( {
    auth: authReducer,
    resetPassword: resetPasswordReducer,
    user: userReducer,
    topRailNav: topRailNavReducer,
});

export default rootReducer;
