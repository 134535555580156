import React from 'react';


class NotFoundPage extends React.Component {
    render() {
        return (
            <div>
                <p>404: Page Not Found</p>
            </div>
        );
    }
}

export default NotFoundPage;
