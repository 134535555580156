
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAIL = "LOG_IN_FAIL";
export const TWO_FACTOR_AUTH_REQUIRED = "TWO_FACTOR_AUTH_REQUIRED";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";
export const CONFIRM_OTP_SUCCESS = "CONFIRM_OTP_SUCCESS";
export const CONFIRM_OTP_FAIL = "CONFIRM_OTP_FAIL";
export const RESEND_OTP = "RESEND_OTP";

export const REQUEST_PASSWORD_SUCCESS = "REQUEST_PASSWORD_SUCCESS";
export const REQUEST_PASSWORD_FAIL = "REQUEST_PASSWORD_FAIL";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";

export const ENSURE_TOKEN_VALIDITY_DONE = "ENSURE_TOKEN_VALIDITY_DONE";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";

export const GET_TRANSFER_SUCCESS = "GET_TRANSFER_SUCCESS";
export const GET_TRANSFER_FAIL = "GET_TRANSFER_FAIL";

export const TRANSFER_SUCCESS = "TRANSFER_SUCCESS";
export const TRANSFER_FAIL = "TRANSFER_FAIL";

export const LOG_IN_TO_DASHBOARD_SUCCESS = "LOG_IN_TO_DASHBOARD_SUCCESS";
export const LOG_IN_TO_DASHBOARD_FAIL = "LOG_IN_TO_DASHBOARD_FAIL";

export const LOG_IN_TO_DESTINATION_PLATFORM_CLIENT_SUCCESS = "LOG_IN_TO_DESTINATION_PLATFORM_CLIENT_SUCCESS";
export const LOG_IN_TO_DESTINATION_PLATFORM_CLIENT_FAIL = "LOG_IN_TO_DESTINATION_PLATFORM_CLIENT_FAIL";

export const GET_SSO_SUCCESS = "GET_SSO_SUCCESS";
export const GET_SSO_FAIL = "GET_SSO_FAIL";

export const CLEAR_STATUS = "CLEAR_STATUS";

export const GET_USERCUSTOMERS_SUCCESS = "GET_USERCUSTOMERS_SUCCESS";
export const GET_USERCUSTOMERS_FAIL = "GET_USERCUSTOMERS_FAIL";

export const GET_USERPRODUCTS_SUCCESS = "GET_USERPRODUCTS_SUCCESS";
export const GET_USERPRODUCTS_FAIL = "GET_USERPRODUCTS_FAIL";

export const UPDATE_SESSION_CUSTOMER_SUCCESS = "UPDATE_SESSION_CUSTOMER_SUCCESS";
export const UPDATE_SESSION_CUSTOMER_FAIL = "UPDATE_SESSION_CUSTOMER_FAIL";
