class StrengthProperties {
    color: string;
    text: string;
    cssTag: string;

    constructor(color: string, text: string, cssTag: string) {
        this.color = color;
        this.text = text;
        this.cssTag = cssTag;
    }
}

class PasswordStrength {
    static weak: StrengthProperties = new StrengthProperties('#9E0215', 'Weak Password', 'weak');
    static okay: StrengthProperties = new StrengthProperties('#FEDC56', 'Okay Password', 'okay');
    static strong: StrengthProperties = new StrengthProperties('#1E8900', 'Strong Password', 'strong');
    static veryStrong: StrengthProperties = new StrengthProperties('#1E8900', 'Very Strong Password', 'very-strong');
    static default: StrengthProperties = new StrengthProperties('black', '', '');
}

export default PasswordStrength;