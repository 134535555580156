import React from "react";
import * as PropTypes from "prop-types";
import {ActiveProductTile} from "./ActiveProductTile";

export const ActiveProducts = ({products, onClickHandler}) => {

    if (products == null || products.length === 0) {
        return <div className="product-container">No products</div>;
    }

    const myActiveSubscriptions = products.map(product => {
        return (
            <div key={product.id}>
                <ActiveProductTile product={product} onClickHandler={onClickHandler}/>
            </div>
        );
    });

    return (
        <div className="product-container">
            {myActiveSubscriptions}
        </div>
    );
};

ActiveProducts.propTypes = {
    products: PropTypes.array,
    onClickHandler: PropTypes.func
};
