import React, { useState, useEffect } from 'react';
import './_style.scss';
import PasswordStrength from './PasswordStrengthProperties';
import PasswordValidator from './PasswordValidator';

interface PasswordStrengthMeterProps {
    password: string;
}

const PasswordStrengthMeter = ({ password }: PasswordStrengthMeterProps) => {
    const [strength, setStrength] = useState(PasswordStrength.default);

    useEffect(() => {
        setStrength(calculateStrength(password.trim()));
    }, [password]);

    const calculateStrength = (password: string) => {
        const hasLowerCase = PasswordValidator.hasLowerCase(password);
        const hasUpperCase = PasswordValidator.hasUpperCase(password);
        const hasNumber = PasswordValidator.hasNumber(password);
        const hasSpecialCharacter = PasswordValidator.hasSpecialCharacter(password);

        const conditionsMet = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharacter].filter(Boolean).length;

        if (password.length < 8) {
            return PasswordStrength.weak;
        } else if (password.length >= 8 && password.length <= 12) {
            if (conditionsMet >= 1 && conditionsMet <= 3) {
                return PasswordStrength.okay;
            }

            if (conditionsMet === 4) {
                return PasswordStrength.strong;
            }
        } else if (password.length > 12) {
            if (conditionsMet === 4) {
                return PasswordStrength.veryStrong;
            }
            return PasswordStrength.okay;
        }
    };


    return (
        <div className="password-strength-meter">
            <div className="meter">
                <div className={`bar ${strength.cssTag}`} />
            </div>
            <div className="strength-section" style={{ color: strength.color }}>
                {strength.text}
            </div>
        </div>
    );
};

export default PasswordStrengthMeter;
