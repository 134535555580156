import React, {useEffect} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {transfer} from "../../../actions/user/userAction";
import "../_style.scss";
import {LoadingLogo} from "../../common/LoadingLogo";

const TransferToHub = ({transferToProduct}) => {

    useEffect(() => {
        transferToProduct("Hub");
    },[transferToProduct]);

    return <div className="dashboard-page">
                <div className="loading-logo-container">
                    <div className="loading-logo">
                        <LoadingLogo/>
                    </div>
                </div>
            </div>;
};

TransferToHub.propTypes = {
    transferToProduct: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        transferToProduct: (product) => dispatch(transfer(product))
    };
}

export default connect(undefined, mapDispatchToProps)(TransferToHub);
