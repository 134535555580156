import React from 'react';
import config from '../../../configs/appSettings';

const MarketingContent = () => {

    const isMonitoringSession = () => {
        const monitoringAgents = config.monitoringAgents?.split(",");

        const isMonitoring = monitoringAgents.find((agent: string) => {
            return window.navigator.userAgent.toLowerCase().indexOf(agent.trim().toLowerCase()) !== -1;
        });

        return isMonitoring != null;
    };

    if (isMonitoringSession())
        return null; 

    return (
        <iframe 
            className="marketing-panel column is-one-third-desktop is-full-width-touch" 
            title="Ncontracts"
            src="https://www.ncontracts.com/customer-login-content"
        />
    );
};

export default MarketingContent;
