import Cookies from 'cookies-js';
import settings from "../configs/appSettings";

function parseJwt(token) {
    if (token === undefined || token === null) return undefined;
    return JSON.parse(window.atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
}

class currentUser {

    constructor() {
        this.cookieAccessToken = settings.cookieName.accessTokenCookieName;
        this.cookieRefreshToken = settings.cookieName.refreshTokenCookieName;
        this.cookieUserId = settings.cookieName.userIdCookieName;
        this.cookieTokenExpirationDate = settings.cookieName.tokenExpirationDateCookieName;
    }

    get accessToken() {
        return Cookies.get(this.cookieAccessToken);
    }

    get accessTokenExpirationDate() {
        return Cookies.get(this.cookieTokenExpirationDate);
    }

    get refreshToken() {
        return Cookies.get(this.cookieRefreshToken);
    }

    get userId() {
        return Cookies.get(this.cookieUserId);
    }

    get fullName() {
        const token = parseJwt(this.accessToken);
        return !token ? '' : token.Name;
    }
    get firstName() {
        const fullName = this.fullName;
        return fullName.split(' ')[0];
    }
    get lastName() {
        const fullName = this.fullName;
        return fullName.split(' ').pop();
    }
    get initials() {
        const first = this.firstName;
        const last = this.lastName;
        return `${first.substring(0,1)}${last.substring(0,1)}`.toUpperCase();
    }

    get customerName() {
        const token = parseJwt(this.accessToken);
        return !token ? '' : token.CurrentCustomerName;
    }

    get customerId() {
        const token = parseJwt(this.accessToken);
        return !token ? '' : token.CurrentCustomerId;
    }
}

export let CurrentUser = new currentUser();
