import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers/rootReducer';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

export default function configureStore(initialState) {

    const middleware = [thunk];

    if (process.env.NODE_ENV !== 'production') {
        middleware.push(reduxImmutableStateInvariant());
    }

    return createStore(rootReducer,
        initialState,
        applyMiddleware(...middleware));
}
