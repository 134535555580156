import {SET_TOP_RAIL_NAVIGATION} from "../../actions/nav/topRailNavAction";

const initialState = {
    activeElementId: undefined
};

export default function topRailNavReducer(state = initialState, action) {
    switch (action.type) {

        case SET_TOP_RAIL_NAVIGATION: {
            return {
                ...state,
                activeElementId: action.activeElementId
            };
        }

        default:
            return state;

    }
}
