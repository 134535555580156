import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {Provider} from "react-redux";
import "./styles/_site.scss";
import "font-awesome/css/font-awesome.min.css";
import "bulma";
import configureStore from "./store/configStore";
import NotFoundPage from "./components/notfound/NotFoundPage";
import MainContainer from "./components/MainContainer";
import AuthContainer from "./components/auth/AuthContainer";
import AuthorizeRoute from "./components/common/AuthorizeRoute";
import TransferPage from "./components/user/TransferPage";
import "Ncontracts.Common.UI/dist/bundle.css";
import { Toaster } from "NstyleComponents";
import ErrorPage from "./components/error/ErrorPage";

const store = configureStore();

export default function App() {
	return <Provider store={store}>
		<Router>
			<div>
				<Toaster />
				<Switch>
					<Redirect exact from="/" to="/app"/>
					<AuthorizeRoute path="/app" component={MainContainer}/>
					<Route path="/auth">
						<AuthContainer/>
					</Route>
					<Route path="/transfer/:nonce">
						<TransferPage/>
					</Route>
					<Route path="/errorPage">
						<ErrorPage />
					</Route>
					<Route>
						<NotFoundPage/>
					</Route>
				</Switch>
			</div>
		</Router>
	</Provider>;
}
