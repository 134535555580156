import http from './httpService';
import { CurrentUser } from '../currentUser';
import appSettings from '../../configs/appSettings';

class userService {
    static getProducts() {
        return new Promise((resolve, reject) => {
            const userProducts =  new Promise((localResolve, localReject) => {
                http.get(`/users/${CurrentUser.userId}/app-switcher/${CurrentUser.customerId}`, null, `${appSettings.customerAdminApiUrl}`)
                .then((result) => {
                    localResolve(result);
                }).catch((err) => {
                    localReject(err);
                });
            });

            const allProductOfferings = new Promise((localResolve, localReject) => {
                http.query("ProductOfferings")
                    .then(result => localResolve(result),
                          err => localReject(err));
            });

            Promise.all([userProducts, allProductOfferings])
                .then(result => resolve(result))
                .catch(err => reject(err));
        });
    }

    static getUserInfo(userId) {
        return new Promise((resolve, reject) => {
            http.query('Users(' + userId + ')')
                .then(result => resolve(result),
                    err => reject(err));
        });
    }

    static getTransferInfo(nonce) {
        return new Promise((resolve) => {
            http.callApiAnonymously('GET', 'transfer/' + nonce)
                .then(result => resolve(result))                
                .catch(err => {
                    window.location.href = `/errorPage`; 
                });
        });
    }

    static loginToDashboard(refreshToken, destinationPlatformClientId) {
        return new Promise((resolve, reject) => {
            http.refreshTokenManually(refreshToken, destinationPlatformClientId)
                .then(() => resolve(true), err => reject(err));
        });
    }

    static loginToDestinationPlatformClient(refreshToken,
                                            destinationPlatformClientId,
                                            destinationPlatformClientAuthUrl,
                                            requestedReturnUrl,
                                            productName)
    {
        return new Promise((resolve, reject) => {
            http.refreshTokenManually(refreshToken, destinationPlatformClientId)
                .then(oauthResult => {
                    http.post('',
                              {
                                    authToken: oauthResult.access_token,
                                    refreshToken: oauthResult.refresh_token,
                                    tokenType: oauthResult.token_type,
                                    authTokenLifetimeInSeconds: oauthResult.expires_in,
                                    userId: oauthResult.userId,
                                    requestedReturnUrl: requestedReturnUrl,
                                    refreshTokenExpirationMinutes: oauthResult.refreshTokenExpirationMinutes,
                                    productName
                              },
                              destinationPlatformClientAuthUrl,
                              true)
                        .then(result => {
                                if (result.redirectUrl) {
                                    return resolve({redirectUrl:  result.redirectUrl});
                                }else if (result.d) {
                                    //To support WebMethod()
                                    if (typeof result.d === "string") {
                                        return resolve({redirectUrl: JSON.parse(result.d).redirectUrl});
                                    }
                                    else {
                                        return resolve({redirectUrl: result.d.redirectUrl});
                                    }
                                }
                                return resolve(true);
                            },
                            () => resolve(false));
                }, err => window.location.href = `/errorPage`);
        });
    }

    static transfer(product) {
        return new Promise((resolve) => {
            http.post('Transfer', { product: product })
                .then(result => resolve(result.redirectUrl))
                .catch(err => {
                    window.location.href = `/errorPage`;
                });
        });
    }
}
export default userService;
