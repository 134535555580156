import React from "react";
import * as PropTypes from "prop-types";
import {DefaultBtn} from "Ncontracts.Common.UI";

export const MoreOfferingTile = ({product}) => {

    const learnMore = () => {
        window.open(product.url, product.productName);
    };


    return (
        <div className={`more-offering-tile`}>
            {product.isNew === true ? <span className="new-product" /> : ""}
            <div className="product-logo-section">
                <img src={product.icon} alt={product.displayName} />
            </div>
            <div className="learn-more-section">
                <div className="columns is-marginless is-paddingless">
                    <div className="column is-6 is-marginless short-description-container">
                        <span className="short-description">{product.shortDescription}</span>
                    </div>
                    <div className="column" style={{textAlign: "right"}}>
                        <DefaultBtn className="btn--smaller btn--solid learn-more-button" onClick={learnMore}>
                            Learn More
                        </DefaultBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

MoreOfferingTile.propTypes = {
    product: PropTypes.object.isRequired
};
