import * as types from '../actionTypes';
import authService from '../../services/api/authService';
import settings from '../../configs/appSettings';
import { Dispatch, Action } from 'redux';
import { Modality, ModalityType } from '../../contracts/types/models/Modality';

export function logIn(username: string, password: string, clientId: string | null, clientRequestedRedirectUrl: string, isSSO: boolean, customerId: string) {
    const cid = clientId == null ? settings.clientId : clientId;
    return function(dispatch: Dispatch<Action>) {
        return authService.logIn(username, password, cid, clientRequestedRedirectUrl, isSSO, customerId)
                          .then((result: any) => dispatch(logInSuccess(result)))
                          .catch((err: any) => dispatch(logInFail(err)));
    };
}

export function selectCustomer(nonce: string, hash: string, clientId: string | null, clientRequestedRedirectUrl: string, isSSO: boolean) {
    const cid = clientId == null ? settings.clientId : clientId;
    return function(dispatch: Dispatch<Action>) {
        return authService.selectCustomer(nonce, hash, cid, clientRequestedRedirectUrl, isSSO)
            .then((result: any) => dispatch(logInSuccess(result)))
            .catch((err: any) => dispatch(logInFail(err)));
    };
}

export function logInSuccess(result: any) {
    return {type: types.LOG_IN_SUCCESS, result};
}

export function logInFail(err: any) {
    return {type: types.LOG_IN_FAIL, error: err};
}

export function confirmOneTimePassword(nonce: string, otp: string, clientId: string | null, clientRequestedRedirectUrl: string, isSSO: boolean, rememberDevice: boolean) {
    const cid = clientId == null ? settings.clientId : clientId;
    return function(dispatch: Dispatch<Action>) {
        return authService.confirmOtp(nonce, otp, cid, clientRequestedRedirectUrl, isSSO, rememberDevice)
            .then((result: any) => dispatch(confirmOtpSuccess(result)))
            .catch((err: any) => dispatch(confirmOtpFail(err)));
    };
}

export function confirmOtpSuccess(result: any) {
    return {type: types.CONFIRM_OTP_SUCCESS, result};
}

export function confirmOtpFail(err: any) {
    return {type: types.CONFIRM_OTP_FAIL, error: err};
}

export function checkSso(emailAddress: string) {
    return function(dispatch: Dispatch<Action>) {
        return authService.checkSsologIn(emailAddress)
                          .then((result: any) => dispatch(checkSsoSuccess(result)))
                          .catch((err: any) => dispatch(checkSsoFail(err)));
    };
}

export function checkSsoInit(httpReferrer: string, hostname: string) {
    // Don't auto login if the user is coming from another page in our domain.
    if (httpReferrer && httpReferrer.indexOf(getDomainName(hostname)) > -1) {
        return function() { return Promise.resolve(); };
    }

    return function(dispatch: Dispatch<Action>) {
        return authService.checkSsologIn()
                          .then((result: any) => dispatch(checkSsoSuccess(result, true)))
                          .catch((err: any) => dispatch(checkSsoFail(err)));
    };
}

function getDomainName(hostName: string)
{
    return hostName.split('.').slice(-2).join('.');
}

export function checkSsoSuccess(result: any, appInit?: boolean) {
    return { type: types.GET_SSO_SUCCESS, result, appInit};
}

export function checkSsoFail(err: any) {
    return {type: types.GET_SSO_FAIL, error: err};
}

export function sendOtp(nonce: string, modality: ModalityType) {
    return function(dispatch: Dispatch<Action>) {
        return authService.sendOtp(nonce, modality)
            .then(() => dispatch(sendOtpSuccess()))
            .catch((err: any) => dispatch(sendOtpFail()));
    };
}

export function sendOtpSuccess() {
    return {type: types.SEND_OTP_SUCCESS};
}

export function sendOtpFail() {
    return {type: types.SEND_OTP_FAIL};
}

export function resendOneTimePassword(nonce: string, modalities: Modality[]) {
    return {type: types.RESEND_OTP, nonce: nonce, modalities: modalities};
}

export function ensureTokenValidity(clientId: string | null) {
    const cid = clientId == null ? settings.clientId : clientId;

    return function(dispatch: Dispatch<Action>) {
        return authService.ensureTokenValidity(cid)
            .then(result => dispatch(ensureTokenValidityDone(result)));
    };
}
export function ensureTokenValidityDone(result: any) {
    return {type: types.ENSURE_TOKEN_VALIDITY_DONE, tokenStillValid : result };
}

export function logout() {
    return (dispatch: Dispatch<Action>) => {
        return authService.logout()
            .then(() => {
                dispatch({type: types.LOG_OUT_SUCCESS });
            });
    };
}

export function clearStatus() {
    return {type: types.CLEAR_STATUS };
}
