import React from "react";
import PropTypes from "prop-types";
import {imageCdnUrls} from "../ImageCdnUrls";
import "./_style.scss";
import {CurrentUser} from "../../../services/currentUser";

export const TopNav = ({ children }) => {
    return (
        <nav className="navbar navbar-main navbar-fixed-top">
            <div className="app-title">
            <img
                src={imageCdnUrls.nLogoNcontractsBlue}
                alt="Ncontracts Logo"
                className="img-responsive"
            />
            </div>

            <div className="top-rail-nav">
            {
                CurrentUser.userId == null
                    ? ""
                    : <>{children}</>
            }
            </div>
        </nav>);
};

TopNav.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};
