const initialState = {
    auth: {
        status: '',
        statusText: '',
        modalities: [],
        nonce: '',
        userInfo: {
            userId: '',
            platformId: '',
            platformRedirectUrl: '',
            production: []
        },
        ssoRedirectUrl: '',
        customerSelectionList: [],
    },
    passwordReset: {
        status: '',
        errors: []
    },
    titleSync: {
        title: ''
    },
    user: {
        status: '',
        activeProducts: [],
        remainingOfferings: [],
        transferInfo: {},
        transfer: {},
        allProducts: [],
        userInfo: {
            id: '',
            firstName: '',
            lastName: ''
        }
    }
};

export default initialState;
