import React from "react";
import "../_style.scss";
import settings from "../../../configs/appSettings";
import TransferToHub from "./TransferToHub";
import LegacyDashboardPage from "./LegacyDashboardPage";

const CompleteLoginPage = () => {
    const transferToHub =  `${settings.forwardToHub ?? false}`;

    return transferToHub === "true"
        ? <TransferToHub />
        : <LegacyDashboardPage />;
};

export default CompleteLoginPage;
