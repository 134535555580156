import settings from "../../configs/appSettings";

export const imageCdnUrls = {
    //illustrations
    assessmentHistory: `${settings.cdnUrl}/illustrations/assessment-history.svg`,
    coffeeSpill: `${settings.cdnUrl}/illustrations/coffee-spill.svg`,
    completeMilestone: `${settings.cdnUrl}/illustrations/complete-milestone.svg`,       // ribbon
    completeInitiative: `${settings.cdnUrl}/illustrations/complete-initiative.svg`,     // check mark
    emptyCalendar: `${settings.cdnUrl}/illustrations/empty-calendar.svg`,
    incompletePuzzle: `${settings.cdnUrl}/illustrations/incomplete-puzzle.svg`,         // puzzle pieces
    milestone: `${settings.cdnUrl}/illustrations/milestone.svg`,                        // mountain with flag
    newUser: `${settings.cdnUrl}/illustrations/new-user.svg`,
    noDataAvailable: `${settings.cdnUrl}/illustrations/no-data-available.svg`,
    noLinked: `${settings.cdnUrl}/illustrations/no-linked.svg`,
    noSearchResults: `${settings.cdnUrl}/illustrations/no-search-results.svg`,          // magnifying glass
    noItemsSelected: `${settings.cdnUrl}/illustrations/no-items-selected.svg`,
    notification: `${settings.cdnUrl}/illustrations/notification.svg`,                  // bell
    noTimeline: `${settings.cdnUrl}/illustrations/no-timeline.svg`,
    plannedInitiative: `${settings.cdnUrl}/illustrations/planned-initiative.svg`,
    strategicInitiative: `${settings.cdnUrl}/illustrations/strategic-initiative.svg`,
    tableEmpty: `${settings.cdnUrl}/illustrations/table-empty.svg`,
    xError: `${settings.cdnUrl}/illustrations/x-error.svg`,

    //icons
    assessmentList: `${settings.cdnUrl}/icons/assessment-list.svg`,
    attachment: `${settings.cdnUrl}/icons/attachment.svg`,
    appSwitcher: `${settings.cdnUrl}/icons/app-switcher.svg`,
    backArrow: `${settings.cdnUrl}/icons/back-arrow.svg`,
    nextArrow: `${settings.cdnUrl}/icons/arrow-next.svg`,
    caretDownWhite: `${settings.cdnUrl}/icons/caret-down-white.svg`,
    caretRightWhite: `${settings.cdnUrl}/icons/caret-right-white.svg`,
    caretDownGrey: `${settings.cdnUrl}/icons/caret-down-grey.svg`,
    caretUpGrey: `${settings.cdnUrl}/icons/caret-up-grey.svg`,
    checkboxSelected: `${settings.cdnUrl}/icons/checkbox-selected.svg`,
    checkboxSelectedGrey: `${settings.cdnUrl}/icons/checkbox-selected-grey.svg`,
    checkboxSelectedGreen: `${settings.cdnUrl}/icons/checkbox-selected-green.svg`,
    checkboxUnselected: `${settings.cdnUrl}/icons/checkbox-unselected.svg`,
    checkmark: `${settings.cdnUrl}/icons/checkmark.svg`,
    checkmarkCircle: `${settings.cdnUrl}/icons/checkmark-circle.svg`,
    datePicker: `${settings.cdnUrl}/icons/date-picker.svg`,
    download: `${settings.cdnUrl}/icons/download.svg`,
    document: `${settings.cdnUrl}/icons/document.svg`,
    documentRed: `${settings.cdnUrl}/icons/document-red.svg`,
    exclamation: `${settings.cdnUrl}/icons/exclamation-circle.svg`,
    filter: `${settings.cdnUrl}/icons/filter.svg`,
    greyX: `${settings.cdnUrl}/icons/x-grey.svg`,
    helpSupport: `${settings.cdnUrl}/icons/help-support.svg`,
    info: `${settings.cdnUrl}/icons/info.svg`,
    key: `${settings.cdnUrl}/icons/key.svg`,
    redX: `${settings.cdnUrl}/icons/x-red.svg`,
    successNotification: `${settings.cdnUrl}/icons/Success-Notification-Icon.svg`,
    switcher: `${settings.cdnUrl}/icons/switcher.svg`,
    threeDots: `${settings.cdnUrl}/icons/3-dots.svg`,
    whiteX: `${settings.cdnUrl}/icons/x-white.svg`,

    //logos
    nLogoBlue: `${settings.cdnUrl}/logos/logo-blue.svg`,
    nLogoDarkGrey: `${settings.cdnUrl}/logos/logo-ncontracts-dark-grey.svg`,
    nLogoGrey: `${settings.cdnUrl}/logos/logo-grey.svg`,
    nLogoWhite: `${settings.cdnUrl}/logos/logo-white.svg`,
    nLogoSettings: `${settings.cdnUrl}/logos/nsettings.svg`,
    nLogoNcontractsBlue: `${settings.cdnUrl}/logos/logo-ncontracts-blue.svg`,

    //animations
    smallLoader: `${settings.cdnUrl}/loading-animations/small-loader.gif`,
    brandedLoader: `${settings.cdnUrl}/loading-animations/branded-loader.gif`,

    //pngs
    singleBlobBackground: `${settings.cdnUrl}/pngs/single-blob-background.png`,
    doubleBlobBackground: `${settings.cdnUrl}/pngs/double-blob-background.png`
};
