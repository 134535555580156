import * as types from '../actionTypes';
import userService from '../../services/api/userService';
import http from '../../services/api/httpService';
import svc from '../../services/api/customerService';

export function getProducts() {
    return function(dispatch) {
        return userService.getProducts()
            .then(result => {
                dispatch({type: types.GET_PRODUCTS_SUCCESS, activeProducts: result[0], productOfferings: result[1]});
            })
            .catch(err => {
                dispatch({type: types.GET_PRODUCTS_FAIL, error: err});
            });
    };
}

export function getUserInfo (userId) {
    return function (dispatch) {
        return userService.getUserInfo(userId)
            .then(result => dispatch({type: types.GET_USER_INFO_SUCCESS, userInfo: result}))
            .catch(err => dispatch({type: types.GET_USER_INFO_FAIL, error: err}));
    };
}

export function getTransferInfo(nonce) {
    return function(dispatch) {
        return userService.getTransferInfo(nonce)
            .then((result) => dispatch({type: types.GET_TRANSFER_SUCCESS, transferInfo: result}))
            .catch((err) => dispatch({type: types.GET_TRANSFER_FAIL, error: err}));
    };
}

export function loginToDashboard(refreshToken, destinationPlatformClientId) {
    return function(dispatch) {
        return userService.loginToDashboard(refreshToken, destinationPlatformClientId)
            .then((result) => dispatch({type: types.LOG_IN_TO_DASHBOARD_SUCCESS, transferInfo: result}))
            .catch((err) => dispatch({type: types.LOG_IN_TO_DASHBOARD_FAIL, error: err}));
    };
}

export function loginToTransferDestination(refreshToken,
                                           destinationPlatformClientId,
                                           destinationPlatformClientAuthUrl,
                                           requestedReturnUrl,
                                           productName) {
    return function(dispatch) {
        return userService.loginToDestinationPlatformClient(refreshToken,
                                destinationPlatformClientId,
                                destinationPlatformClientAuthUrl,
                                requestedReturnUrl, productName)
            .then((result) => dispatch({type: types.LOG_IN_TO_DESTINATION_PLATFORM_CLIENT_SUCCESS, transferInfo: result}))
            .catch((err) => dispatch({type: types.LOG_IN_TO_DESTINATION_PLATFORM_CLIENT_FAIL, error: err}));
    };
}

export function transfer(product) {
    return function(dispatch) {
        return userService.transfer(product)
            .then((result) => dispatch({type: types.TRANSFER_SUCCESS, transferUrl: result}))
            .catch((err) => dispatch({type: types.TRANSFER_FAIL, error: err}));
    };
}

export function updateSessionCustomer(customerId) {
    return function(dispatch) {
        return http.updateSessionCustomer(customerId)
            .then((result) => dispatch({type: types.UPDATE_SESSION_CUSTOMER_SUCCESS, result: result}))
            .catch((err) => dispatch({type: types.UPDATE_SESSION_CUSTOMER_FAIL, error: err}));
    };
}

export function getUserCustomers() {
    return function(dispatch) {
        return svc.getUserCustomers()
            .then(result => dispatch({type: types.GET_USERCUSTOMERS_SUCCESS, result: result}))
            .catch(err => dispatch({type: types.GET_USERCUSTOMERS_FAIL, error: err}));
    };
}

export function getUserProducts(customerId) {
    return function(dispatch) {
        return svc.getUserProducts(customerId)
            .then(result => dispatch({type: types.GET_USERPRODUCTS_SUCCESS, result: result}))
            .catch(err => dispatch({type: types.GET_USERPRODUCTS_FAIL, error: err}));
    };
}
