import $ from 'jquery';
import camelcaseKey from 'camelcase-keys';
import settings from '../../configs/appSettings';
import moment from 'moment';
import {CurrentUser} from '../currentUser';
import authService from './authService';

class httpService {

    constructor() {
        $.ajaxSetup({
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            }
        });
    }

    get(url, query, baseUrl) {

        return new Promise((resolve, reject) => {
            this.refreshToken();

            const baseApi = !baseUrl ? settings.authApiUrl + '/api/' : baseUrl;

            return $.ajax({
                url: baseApi + url.trim(),
                type: 'GET',
                data: query,
                cache: false,
                headers: {
                    "Authorization": "Bearer " + CurrentUser.accessToken
                }
            })
                .fail(error => reject(error))
                .done(result => resolve(result));
        });
    }

    query(url) {
        return new Promise((resolve, reject) => {
            this.get('query/' + url)
                .then(result => resolve(camelcaseKey(result, {deep: true})),
                      err => reject(err)
                     );
        });
    }

    post(url, data, baseUrl, withCredential) {
        return new Promise((resolve, reject) => {
            this.performAjaxCall(url, data, 'POST', 'application/json', baseUrl, withCredential)
                .then(result => {
                    if (result == null)
                        return resolve();

                    if (typeof result === 'string' && result.trim().length === 0)
                        return resolve();

                    return resolve(camelcaseKey(result, {deep: true}));
                },
                    err => reject(err)
                );
        });
    }

    put(url, data, baseUrl) {
        return this.performAjaxCall(url, data, 'PUT', 'application/json', baseUrl);
    }

    delete(url, data, baseUrl) {
        return this.performAjaxCall(url, data, 'DELETE', 'application/json', baseUrl);
    }

    performAjaxCall(url, data, actionType, contentType, baseUrl, withCredential) {
        this.refreshToken();

        let baseApi = !baseUrl ? settings.authApiUrl + '/api/' : baseUrl;

        let payload = {
            url: baseApi + url.trim(),
            data: data === null ? '{}' : JSON.stringify(data),
            type: actionType,
            contentType: contentType,
            headers: {"Authorization": "Bearer " + CurrentUser.accessToken}
        };

        if (withCredential)
            payload.xhrFields = {
                withCredentials: true
            };

        return $.ajax(payload)
            .fail(() => {})
            .done(() => {});
    }

    login(userId, password, clientId, customerId) {
        this.clientId = clientId;
        return new Promise((resolve, reject) => {

            this.callOAuth('grant_type=password&client_id='+ clientId +'&username=' + encodeURIComponent(userId) + '&password=' + encodeURIComponent(password) + '&customer_id=' + encodeURIComponent(customerId), true)
                .then(result => {
                    resolve(result);
                }, err => {
                    reject(err);
                });
        });
    }

    confirmOtp(nonce, otp, clientId, rememberDevice) {
        this.clientId = clientId;
        return new Promise((resolve, reject) => {
            this.callOAuth('grant_type=password&client_id='+ clientId + '&rememberDevice=' + rememberDevice, false,{ 'X-NContracts-Nonce': nonce, 'X-NContracts-OTP': otp })
                .then(result => resolve(result),
                      err => reject(err));
        });
    }

    selectCustomer(nonce, selectedCustomerHash, clientId) {
        this.clientId = clientId;
        return new Promise((resolve, reject) => {
            this.callOAuth('grant_type=password&client_id='+ clientId, false,{ 'X-NContracts-Nonce': nonce, 'X-NContracts-selected-customer': selectedCustomerHash })
                .then(result => resolve(result),
                      err => reject(err));
        });
    }

    callApiAnonymously(actionType, url, data, baseUrl) {
        return new Promise((resolve, reject) => {
            let baseApi = !baseUrl ? settings.authApiUrl + '/api/' : baseUrl;

            let payload = {
                url: baseApi + url.trim(),
                data: data === null ? '{}' : JSON.stringify(data),
                type: actionType,
                contentType: 'application/json'
            };

            $.ajax(payload)
             .fail(err => reject(err))
             .done(data => resolve(data));
        });
    }

    refreshToken(clientId) {
        if (clientId != null)
            this.clientId = clientId;

        return new Promise((resolve) => {
            if (CurrentUser.accessTokenExpirationDate != null) {
                const dt = new Date(CurrentUser.accessTokenExpirationDate);

                if (moment().add(1, 'minutes') < dt.getTime()) {
                    return resolve(true);
                }
            }

            if (this.clientId == null || CurrentUser.refreshToken == null)
                return;

            this.callOAuth('grant_type=refresh_token&client_id='+ this.clientId + '&refresh_token=' + encodeURIComponent(CurrentUser.refreshToken), false)
                .then(() => resolve(true),
                      () => resolve(false));
        });
    }

    refreshTokenManually(refreshToken, clientId) {
        if (clientId != null)
            this.clientId = clientId;

        return new Promise((resolve, reject) => {
            $.ajax(
                {
                    url: settings.authApiUrl + '/login',
                    type: 'POST',
                    async: true,
                    data: 'grant_type=refresh_token&client_id='+ clientId + '&refresh_token=' + encodeURIComponent(refreshToken),
                    contentType: 'application/x-www-form-urlencoded'
                })
                .done(result => resolve(result))
                .fail(error => reject(error));
        });
    }

    updateSessionCustomer(customerId) {
        return new Promise((resolve, reject) => {
            this.put('customerSwitcher/updateSession', { userId: CurrentUser.userId, customerId: customerId })
                .then(result => resolve(result))
                .catch(err => {
                    if (err.status === 401) {
                        //attempt to refresh access token
                        this.refreshToken(settings.clientId)
                            .then(success => {
                                if (success) {
                                    this.put('customerSwitcher/updateSession', { userId: CurrentUser.userId, customerId: customerId })
                                        .then(result => resolve(result))
                                        .catch(err => reject(err));
                                } else {
                                    authService.logout();
                                    window.location.href = new URL("/auth/login", window.location.href);
                                }
                            });
                    } else {
                        authService.logout();
                        window.location.href = new URL("/auth/login", window.location.href);
                    }
                });
        });
    }

    callOAuth(dataContent, isAsync, headers) {
        return $.ajax(
            {
                url: settings.authApiUrl + '/login',
                type: 'POST',
                async: isAsync,
                data: dataContent,
                headers: headers,
                contentType: 'application/x-www-form-urlencoded'
            })
            .done(() => {})
            .fail(() => {});
    }

    get clientId() { return this._clientId; }
    set clientId(value) { this._clientId = value; }
}

const svc = new httpService();

export default svc;
