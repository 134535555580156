import * as types from '../actionTypes';
import authService from '../../services/api/authService';
import { Dispatch } from 'redux';

export function requestPasswordSuccess() {
    return { type: types.REQUEST_PASSWORD_SUCCESS };
}

export function requestPasswordFail() {
    return { type: types.REQUEST_PASSWORD_FAIL };
}

export function requestPassword(email: string, referrerPlatform: string) {
    return function(dispatch: Dispatch) {
        return authService.requestPassword(email, referrerPlatform)
            .then(() => dispatch(requestPasswordSuccess()))
            .catch(() => dispatch(requestPasswordFail()));
    };
}
