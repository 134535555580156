import { BaseBtn, PrimaryHeader, PrimaryText, RadioButtonGroup, TertiaryHeader } from 'NstyleComponents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../actions/auth/signInAction';
import { DocTitle } from '../../common/DocTitle';
import './_style.scss';
import { Modality, ModalityType } from '../../../contracts/types/models/Modality';
import { imageCdnUrls } from '../../../components/common/ImageCdnUrls';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';

const TwoFASelectionPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedDestination, setSelectedDestination] = useState<string>("");
    const [selectedModalityType, setselectedModalityType] = useState<ModalityType>(ModalityType.email);
    const [working, setWorking] = useState<boolean>(false);

    const status = useSelector((state: any) => state.auth.status);
    const twoFactorMethods: Modality[] = useSelector((state: any) => state.auth.modalities);
    const nonce = useSelector((state: any) => state.auth.nonce);

    useEffect(() => {
        if (status !== "2FASelection" && status !== "OtpSent") {
            history.push('/auth/login');
        }

        setSelectedDestination(twoFactorMethods[0].destination);
        sessionStorage.setItem('selectedModality', ModalityType.email);
    }, []);

    const handleModalityChanged = (value: string) => {
        const selectedModalityType = twoFactorMethods.find(modality => modality.destination === value);
        setselectedModalityType(selectedModalityType.modality);
        setSelectedDestination(value);

        sessionStorage.setItem('selectedModality', selectedModalityType.modality);
    };

    const sendOneTimePassword = () => {
        if (working) return;
        setWorking(true);
        dispatch(actions.sendOtp(nonce, selectedModalityType));
    };

    useEffect(() => {
        if (status === "OtpSent") {
            history.push('/auth/confirmIdentificationCode');
        }
    }, [status]);

    return (
        <div>
            <DocTitle syncTo="Select how you want to receive your temporary code" />
            <div style={{ background: `url(${imageCdnUrls.doubleBlobBackground}) no-repeat center / cover` }} className={"two-fa-page"}>
                <div className={"two-fa-page__form"}>
                    <div className={"two-fa-page__form-header"}>
                        <ReactSVG
                            src={imageCdnUrls.nLogoDarkGrey}
                            className={"two-fa-page__form-header-logo"}
                        />
                        <PrimaryHeader className={"two-fa-page__form-header-title"}>Send one time code.</PrimaryHeader>
                        <PrimaryText className={"two-fa-page__form-header-subtitle"}>
                            For your security, we need to verify your identity.
                            <br />
                            Below are the email addresses and phone numbers you have listed with us.
                        </PrimaryText>
                        <TertiaryHeader>Pick your delivery method.</TertiaryHeader>
                    </div>
                    <div className={"two-fa-page__form-body"}>
                        <RadioButtonGroup
                            onChange={value => handleModalityChanged(value)}
                            options={twoFactorMethods.map(method => method.destination)}
                            selectedOption={selectedDestination}
                        />
                        <div className={"two-fa-page__form-field"}>
                            <BaseBtn
                                className={"two-fa-page__send-code-btn"}
                                onClick={sendOneTimePassword}>
                                Send Code
                            </BaseBtn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoFASelectionPage;
