import { BaseBtn, BaseNoPaddingBtn, PrimaryHeader, PrimaryLabel, SingleLineInput, toast } from "NstyleComponents";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from "react-svg";
import * as actions from '../../../actions/auth/forgotPasswordAction';
import { imageCdnUrls } from "../../../components/common/ImageCdnUrls";
import validator from '../../../services/validationHelper';
import { DocTitle } from '../../common/DocTitle';
import './_style.scss';


interface SendPasswordResetLinkProps {
    referrer: any
}

const SendPasswordResetLink = ({
    referrer
}: SendPasswordResetLinkProps) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [userName, setUserName] = useState<string>("");
    const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");

    const handleSendResetPasswordLinkClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent) => {
        handleSendResetPasswordLink(event);
    };

    const handleKeyPressed = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSendResetPasswordLinkClicked(event);
        }
    };

    const handleSendResetPasswordLink = (event: React.MouseEvent | React.KeyboardEvent) => {
        event.preventDefault();

        if (validate()) {
            dispatch(actions.requestPassword(userName, referrer.platform));
            toast.success("Sent! Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.");
        }
    };

    const handleUserNameChanged = (userName: string) => {
        setUserName(userName);
        setEmailIsValid(true);
    };

    const validate = () => {

        if (userName.trim().length === 0) {
            setEmailErrorMessage('Please enter your email.');
            setEmailIsValid(false);
            return false;
        }

        if (!validator.validationEmail(userName)) {
            setEmailErrorMessage('The email address you entered is invalid. Please enter a valid email address.');
            setEmailIsValid(false);
            return false;
        }

        return true;
    };

    return (
        <div>
            <DocTitle syncTo="Password Recovery Assistance" />
            <div style={{ background: `url(${imageCdnUrls.doubleBlobBackground}) no-repeat center / cover` }} className={"resetPW-page"}>
                <div className={"resetPW-page__form"}>
                    <div className={"resetPW-page__form-header"}>
                        <BaseNoPaddingBtn
                            className={"resetPW-page__form-header-back-btn"}
                            onClick={() => history.push("/auth")}>
                            {'< Back'}
                        </BaseNoPaddingBtn>
                        <ReactSVG
                            src={imageCdnUrls.nLogoDarkGrey}
                            className={"resetPW-page__form-header-logo"}
                        />
                        <PrimaryHeader className={"resetPW-page__form-header-title"}>Reset Password</PrimaryHeader>
                    </div>
                    <div className={"resetPW-page__form-body"}>
                        <div className={"resetPW-page__form-field"}>
                            <PrimaryLabel className={"resetPW-page__form-field-label"}>Enter email</PrimaryLabel>
                            <SingleLineInput
                                onChange={(email) => handleUserNameChanged(email)}
                                value={userName}
                                placeholder={"example@gmail.com"}
                                isValid={emailIsValid}
                                onKeyPress={e => handleKeyPressed(e)}
                                {...{ autoFocus: true }}
                                className={"resetPW-page__form-field-input"}
                            />
                            {!emailIsValid
                                ?
                                <div className={"resetPW-page__error"}>
                                    {emailErrorMessage}
                                </div>
                                : <></>
                            }
                            <BaseBtn
                                className={"resetPW-page__reset-password-btn"}
                                onClick={handleSendResetPasswordLinkClicked}
                            >

                                Send password reset link
                            </BaseBtn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendPasswordResetLink;
