import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";
import _ from "lodash";
import authResource from "../../assets/responses/authErrorResource";

export default function signInReducer(state = {...initialState.auth}, action) {
    switch (action.type) {
        case actionTypes.LOG_IN_SUCCESS: {
            const userInfo = {
                userId: action.result.userId,
                platformId: action.result.platformId,
                platformRedirectUrl: action.result.redirectTo
            };
            return {...state, status: "LoggedIn", userInfo: userInfo};
        }

        case actionTypes.LOG_IN_FAIL: {
            try {
                if (typeof action.error.responseJSON.error === "object") {
                    let someError = action.error.responseJSON.error;
                    // eslint-disable-next-line no-prototype-builtins
                    if (authResource.hasOwnProperty(someError.errorCode)) {
                        return {...state,
                                    status: "LogInToPlatformError",
                                    statusText: authResource[someError.errorCode]};
                    }
                } else {

                    const errorObj = JSON.parse(action.error.responseJSON.error);

                    if (errorObj.passwordRequired === true) {
                        return {...state, status: "PasswordExpired", nonce: errorObj.resetNonce, userId: errorObj.userId };
                    }
                    if (errorObj.accountLocked === true) {
                        return {...state, status: "AccountLocked", userId: errorObj.userId };
                    }
                    if (errorObj.invalidIPAddress === true) {
                        return {...state, status: "InvalidIpAddress", userId: errorObj.userId };
                    }
                    if (errorObj.idpInitRequired === true) {
                        return {...state,
                            status: "UserHasIdpInit",
                            ssoRedirectUrl: errorObj.loginRedirectUrl
                        };
                    }
                    if (errorObj.spInitRequired === true) {
                        return {...state, 
                            status: "UserHasSpInit",
                            customerId: errorObj.customerId };
                    }
                    if (errorObj.invalidUsernamePassword === true) {
                        return {...state, status: "InvalidUsernamePassword" };
                    }
                    if (errorObj.authenticationFailed === true) {
                        return {...state, status: "LogInFail" };
                    }
                    if (errorObj.twoFactorAuthRequired === true) {
                        const nonce = action.error.getResponseHeader("X-Ncontracts-Nonce") || errorObj.getResponseHeader("X-NContracts-Nonce");

                        return {...state,
                                    userId: errorObj.userId,
                                    nonce: nonce,
                                    status: "2FASelection",
                                    modalities: _.map(errorObj.destinations, function (d) {
                                        return {
                                            modality: d.modality,
                                            destination: d.destination
                                        };
                                    })
                        };
                    }
                    if (errorObj.customerSelectionNeeded === true) {
                        const nonce = action.error.getResponseHeader("X-Ncontracts-Nonce") || errorObj.getResponseHeader("X-NContracts-Nonce");

                        const customers = errorObj.customers.map(c => {
                            return {
                                customerName: c.customerName,
                                hash: c.hash,
                                errorMessage: c.errorMessage
                            };
                        });

                        return {...state,
                                userId: errorObj.userId,
                                nonce: nonce,
                                status: "CustomerSelectionNeeded",
                                customerSelectionList: _.orderBy(customers, "customerName")
                        };
                    }

                }
                return {...state, status: "LogInFail" };
            }
            catch (exception) {

                return {...state, status: "LogInFail" };
            }
        }

        case actionTypes.TWO_FACTOR_AUTH_REQUIRED:
            return {...state, status: "2FARequired"};

        case actionTypes.SEND_OTP_SUCCESS:
            return {...state, status: "OtpSent"};

        case actionTypes.SEND_OTP_FAIL:
            return {...state, status: "FailToSendOtp"};

        case actionTypes.RESEND_OTP:
            return {...state,
                        status: "2FASelection",
                        nonce: action.nonce,
                        modalities: action.modalities };

        case actionTypes.CONFIRM_OTP_SUCCESS: {
            return {...state,
                status: "LoggedIn",
                userInfo: {
                    userId: action.result.userId,
                    platformRedirectUrl: action.result.redirectTo
                }
            };
        }

        case actionTypes.CONFIRM_OTP_FAIL:
            return {...state, status: "OtpFail" };

        case actionTypes.ENSURE_TOKEN_VALIDITY_DONE:
            return {...state, status: "TokenVerified", tokenStillValid: action.tokenStillValid };

        case actionTypes.LOG_OUT_SUCCESS: {
            return {...state, status: "LoggedOut" };
        }

        case actionTypes.GET_SSO_SUCCESS: {
            if (action.result === null) {
                return {...state, status: "UserDoesNotHaveSso", ssoRedirectUrl: "" };
            } else {
                let status = "UserDoesNotHaveSso";

                if (action.result.isSpInitiated) {
                    status = "UserHasSpInit";
                } else if (action.result.isIdpInitiated) {
                    status = "UserHasIdpInit";
                } else if ( action.appInit ) {
                    return {...state, status: "InitialUnknownLogin" };    
                }

                return {...state,
                    status: status,
                    ssoRedirectUrl: action.result.loginRedirectUrl,
                    customerId: action.result.customerId
                };
            }
        }

        case actionTypes.GET_SSO_FAIL: {
            return {...state, status: "UserDoesNotHaveSso", ssoRedirectUrl: "" };
        }

        case actionTypes.CLEAR_STATUS: {
            return {...state, status: ""};
        }

        default:
            return state;
    }
}
