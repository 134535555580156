import http from './httpService';
import { CurrentUser } from '../currentUser';
import appSettings from '../../configs/appSettings';
import authService from './authService';

class customerService {
    static getUserCustomers() {
        return new Promise((resolve, reject) => {
            http.get('customerSwitcher/' + CurrentUser.userId)
                .then(result => resolve(result))
                .catch(err => reject(err));
        });
    }

    static getUserProducts(customerId) {
        return new Promise((resolve, reject) => {
            http.get(`/users/${CurrentUser.userId}/app-switcher/${customerId}`, null, `${appSettings.customerAdminApiUrl}`)
                .then(result => resolve(result))
                .catch(err => {
                    if (err.status === 401) {
                        //attempt to refresh access token
                        http.refreshToken(appSettings.clientId)
                            .then(success => {
                                if (success) {
                                    http.get(`/users/${CurrentUser.userId}/app-switcher/${customerId}`, null, `${appSettings.customerAdminApiUrl}`)
                                        .then(result => resolve(result))
                                        .catch(err => reject(err));
                                } else {
                                    authService.logout();
                                    window.location.href = new URL("/auth/login", window.location.href);                               
                                }
                            });
                    } else {
                        authService.logout();
                        window.location.href = new URL("/auth/login", window.location.href);
                    }
                });
        });
    }
}

export default customerService;
