import config from '../../configs/appSettings';

let standardPlatformCallbackAuthFailure =
    `There is a problem with the configuration of your account. Please contact ${config.supportInfo.email} with the following error code: `;

const errorMessages = {
    A01: `${standardPlatformCallbackAuthFailure} A01`,
    A02: `${standardPlatformCallbackAuthFailure} A02`,
    A03: `${standardPlatformCallbackAuthFailure} A03`,
};

export default errorMessages;
