import React, {useEffect} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {LoadingLogo} from "../../common/LoadingLogo";
import {ActiveProducts} from "../ActiveProducts";
import {MoreOfferings} from "../MoreOfferings";
import {DocTitle} from "../../common/DocTitle";
import {getProducts, transfer} from "../../../actions/user/userAction";

const LegacyDashboardPage = ({myProducts, moreOfferings, transferToProduct, getProducts, ready}) => {

    useEffect(() => {
        getProducts();
    },[ready, getProducts]);

    if (ready === false) {
        return (
            <div className="dashboard-page">
                <div className="loading-logo-container">
                    <div className="loading-logo">
                        <LoadingLogo/>
                    </div>
                </div>
            </div>
        );
    }

    const transfer = product => {
        transferToProduct(product.name);
    };

    const hasOnlyOneProduct = myProducts !== undefined && myProducts.length === 1;

    if (hasOnlyOneProduct) {
        transferToProduct(myProducts[0].name);
        return "";
    }

    return (
        <div className="dashboard-page main-content">
            <div className="section-header">My Active Subscriptions</div>
            <ActiveProducts products={myProducts}  onClickHandler={transfer} />
            {
                moreOfferings != null && moreOfferings.length > 0
                    ? (<>
                        <div className="section-header">More Ncontracts Offerings</div>
                        <MoreOfferings products={moreOfferings} />
                        <DocTitle syncTo={`Ncontracts - Welcome`} />
                    </>)
                    : ""
            }
        </div>
    );
};

LegacyDashboardPage.propTypes = {
    myProducts: PropTypes.array,
    moreOfferings: PropTypes.array,
    transferToProduct: PropTypes.func,
    getProducts: PropTypes.func,
    ready: PropTypes.bool
};

LegacyDashboardPage.defaultProps = {
    ready: false
};

function mapStateToProps(state) {
    return {
        myProducts: state.user.activeProducts,
        moreOfferings: state.user.remainingOfferings,
        ready: state.user.status === "ProductsFetched"
    };
}

function mapDispatchToProps(dispatch) {
    return {
        transferToProduct: (product) => dispatch(transfer(product)),
        getProducts: () => dispatch(getProducts())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LegacyDashboardPage);
