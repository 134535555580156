import React from "react";
import * as PropTypes from "prop-types";
import {MoreOfferingTile} from "./MoreOfferingTile";

export const MoreOfferings = ({products}) => {

    if (products == null || products.length === 0) {
        return <div className="product-container">No products</div>;
    }

    const otherProducts = products.map(product => {
        return (
            <div key={product.id}>
                <MoreOfferingTile product={product} />
            </div>
        );
    });

    return (
        <div className="product-container">
            {otherProducts}
        </div>
    );
};

MoreOfferings.propTypes = {
    products: PropTypes.array
};
