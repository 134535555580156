import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectCustomer } from "../../../actions/auth/signInAction";
import { DocTitle } from "../../common/DocTitle";
import { TopNav } from "../../common/TopNav/TopNav";
import './_style.scss';

const CustomerSelectionPage = ({
  customers,
  customerSelected,
  nonce,
  referrer,
  authenticationStatus,
  history,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleAuthenticationStatusChanged = useCallback(() => {
    switch (authenticationStatus) {
      case "2FASelection": {
        history.replace("/auth/requestIdentificationMethods");
        break;
      }

      case "PasswordExpired": {
        history.replace(`/auth/ResetPassword/${nonce}?e=1`);
        break;
      }
      case "InvalidIpAddress": {
        setErrorMessage("Your IP Address is not allowed.");
        break;
      }
      default: {
        break;
      }
    }
  }, [authenticationStatus, history, nonce]);

  useEffect(() => {
    handleAuthenticationStatusChanged();
  }, [authenticationStatus, handleAuthenticationStatusChanged]);

  const handleTileClicked = (selectedItem) => {
    customerSelected(nonce, selectedItem.hash, referrer);
  };

  return (
    <div className="content-panel">
      <TopNav />
      <div className="customer-selection-page main-content">
        <div className="customer-selection-title">Select your institution</div>
        {errorMessage != null && errorMessage.length > 0 ? (
          <div className="authentication-error-message">{errorMessage}</div>
        ) : (
          ""
        )}
        <div className="customer-list-container">
          {customers.map((c) => {
            const accessible = c.errorMessage == null || c.errorMessage.length === 0;

            if (accessible === true) {
              return (
                <div
                  key={c.hash}
                  className="customer-tile"
                  onClick={() => handleTileClicked(c)}
                  data-automation={encodeURI(c.customerName)}
                >
                  <div>{c.customerName}</div>
                </div>
              );
            }

            const accessibleClass = accessible === true ? "" : " not-accessible ";

            return (
              <div
                key={c.hash}
                className={`customer-tile ${accessibleClass} has-tooltip-bottom has-tooltip-multiline`}
                data-tooltip={c.errorMessage}
              >
                <div>{c.customerName}</div>
              </div>
            );
          })}
        </div>
      </div>
      <DocTitle syncTo={"Ncontracts - Customer Selection Page"} />
    </div>
  );
};

CustomerSelectionPage.propTypes = {
   customers: PropTypes.array,
   customerSelected: PropTypes.func,
   referrer: PropTypes.object,
   nonce: PropTypes.string,
   authenticationStatus: PropTypes.string,
   history: PropTypes.object,
};

CustomerSelectionPage.defaultProps = {
   customers: [],
};

function mapStateToProps(state) {
  return {
    customers: state.auth.customerSelectionList,
    nonce: state.auth.nonce,
    authenticationStatus: state.auth.status,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    customerSelected: (nonce, hash, platformInfo) =>
      dispatch(
        selectCustomer(
          nonce,
          hash,
          platformInfo.platform,
          platformInfo.platformRequestedRedirectUrl,
          platformInfo.isSSO
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelectionPage);
