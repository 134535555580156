import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import authService from '../../services/api/authService';

const AuthorizeRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        authService.loggedIn()
            ? ( <Component {...props}/>)
            : ( <Redirect to={{
                pathname: '/auth/login',
                state: { redirectFrom: props.location }
            }}/>
        )
    )}/>
);

AuthorizeRoute.propTypes = {
    location: PropTypes.object,
    component: PropTypes.func
};

export default AuthorizeRoute;


