import React from 'react';

interface DocTitleProps {
    syncTo?: string;
}

export const DocTitle: React.FC<DocTitleProps> = ({ syncTo = "Ncontracts" }) => {
    document.title = syncTo;

    return <div />;
};
