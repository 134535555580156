import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withRouter} from 'react-router-dom';
import {DocTitle as DocWrapper} from '../common/DocTitle';
import * as actions from '../../actions/user/userAction';
import './_transfer.scss';
import Loader from 'react-loader-advanced';
import {LoadingLogo} from "../common/LoadingLogo";

class TransferPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);

        this.state = {
            status: '',
            statusText: 'Authenticating...'
        };
    }

    componentWillMount() {
        this.props.getTransferInfo(this.props.match.params.nonce);
    }

    componentWillReceiveProps(nextProps) {
        switch (nextProps.transferInfo.status) {
            case "TransferInfoReceived":
                this.setState(Object.assign({}, this.state, { statusText: '' }));

                if (nextProps.transferInfo.product === "Dashboard"){
                    this.props.loginToDashboard(nextProps.transferInfo.refreshToken, nextProps.transferInfo.platformClientId, nextProps.transferInfo.platformClientAuthUrl);
                    break;
                }
                else {
                    this.props.loginToDestination(nextProps.transferInfo.refreshToken, nextProps.transferInfo.platformClientId, nextProps.transferInfo.platformClientAuthUrl, nextProps.transferInfo.destinationUrl, nextProps.transferInfo.product);
                    break;
                }
            case "LoggedInToDestination":
                this.setState(Object.assign({}, this.state, { statusText: '' }));
                window.location.replace(nextProps.transferInfo.destinationUrl);
                break;
            default:
                break;
        }
    }

    render() {
        const loading =
            (<div>
                <div className="title">{this.state.statusText}</div>
                <LoadingLogo />
            </div>);

        let showing = true;

        return(
            <div className="transfer">
                <DocWrapper syncTo="Redirecting..." />
                <div className="columns is-centered">
                    <div className="transfer-main column is-three-quarters-touch is-one-third-desktop">
                        <div>
                            <Loader message={loading} show={showing}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


TransferPage.propTypes = {
    getTransferInfo: PropTypes.func,
    loginToDestination: PropTypes.func,
    loginToDashboard: PropTypes.func,
    transferInfo: PropTypes.object,
    match: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        getTransferInfo: (nonce) => dispatch(actions.getTransferInfo(nonce)),
        loginToDestination: (refreshToken, destinationPlatformClientId, destinationPlatformClientAuthUrl,requestedReturnUrl, productName) =>
                dispatch(actions.loginToTransferDestination(refreshToken, destinationPlatformClientId, destinationPlatformClientAuthUrl, requestedReturnUrl, productName)),
        loginToDashboard: (refreshToken, destinationPlatformClientId, destinationPlatformClientAuthUrl) =>
            dispatch(actions.loginToDashboard(refreshToken, destinationPlatformClientId, destinationPlatformClientAuthUrl))
    };
}

function mapStateToProps(state) {

    return {
        transferInfo: state.user.transferInfo
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransferPage));
