import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { Route, withRouter} from "react-router-dom";
import * as signInActions from "../actions/auth/signInAction";
import * as userActions from "../actions/user/userAction";
import {TopNav} from "./common/TopNav/TopNav";
import SupportNav from "./common/TopNav/SupportNav";
import UserNav from "./common/TopNav/UserNav";
import "../styles/_site.scss";
import CustomerSwitcherPage from "./auth/customerSelection/CustomerSwitcherPage";
import {logout} from "../actions/auth/signInAction";
import CompleteLoginPage from "./Dashboard/page/CompleteLoginPage";

class MainContainer extends React.Component {

    componentWillReceiveProps() {
        if (this.props.status === "TokenVerified") {
            if (!this.props.tokenStillValid) {
                logout().then(() => {
                    this.props.history.push("/auth/login");
                }).error(() => {
                    this.props.history.push("/auth/login");
                });
            }
        }
    }

    componentWillUpdate() {
        if (this.props.location !== this.props.history.location) {
            this.props.verifyToken();
        }
    }

    componentDidUpdate() {
        if (this.props.transfer.status === "TransferSuccess"){
            window.location.replace(this.props.transfer.transferUrl);
        }
    }

    render() {
        return(
            <div className="content-panel">
                <TopNav>
                    <SupportNav transferToProduct={this.props.transferToProduct} />
                    <UserNav />
                </TopNav>
                <div>
                    <Route exact path="/app" render={props => <CompleteLoginPage {...props} />} />
                    <Route path="/app/dashboard" render={props => <CompleteLoginPage {...props} />} />
                    <Route path="/app/customer-switcher" render={props => <CustomerSwitcherPage {...props} />} />
                </div>
            </div>
        );
    }
}


MainContainer.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    tokenStillValid: PropTypes.bool,
    verifyToken: PropTypes.func,
    transferToProduct: PropTypes.func,
    transfer: PropTypes.object,
    status: PropTypes.string,
    logout: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        verifyToken: (clientId) => dispatch(signInActions.ensureTokenValidity(clientId)),
        transferToProduct: (productName) => dispatch(userActions.transfer(productName)),
        logout: () => dispatch(logout())
    };
}

function mapStateToProps(state) {
    return {
        tokenStillValid: state.auth.tokenStillValid,
        status: state.auth.status,
        transfer: state.user.transfer
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
