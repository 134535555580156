import settings from "../configs/appSettings";

export default function addTracking(userId: string) {
    const trackingUrl = `${settings.productTrackingUrl}${userId}.js`;
    let script = document.createElement('script');

    script.src = trackingUrl;
    script.async = true;
    script.type = "text/javascript";
    document.head.appendChild(script);
}
