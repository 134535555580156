import * as types from '../actionTypes';
import authService from '../../services/api/authService';
import { Dispatch } from 'redux';

export function resetPasswordSuccess() {
    return { type: types.RESET_PASSWORD_SUCCESS };
}

export function resetPasswordFail(err: string) {
    return { type: types.RESET_PASSWORD_FAIL, error: err };
}

export function resetPassword(nonce: string, password: string) {
    return function(dispatch: Dispatch) {
        return authService.resetPassword(nonce, password)
            .then(() => dispatch(resetPasswordSuccess()))
            .catch((err: any) => dispatch(resetPasswordFail(err.responseJSON.reason)));
    };
}
