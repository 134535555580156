import * as actionTypes from '../../actions/actionTypes';
import _ from "lodash";

const initialState = {
    status: "",
    activeProducts: [],
    remainingOfferings: [],
    transferInfo: {},
    transfer: {},
    userInfo: {
        id: "",
        firstName: "",
        lastName: ""
    },
    userCustomers: []
};

export default function userReducer(state = initialState, action) {

    const mapProductDto = p => {
        return {
            displayName: p.displayName,
            name: p.name,
            icon: p.icon,
        };
    };

    switch (action.type) {

        case actionTypes.GET_PRODUCTS_SUCCESS: {
            const activeProducts = action.activeProducts.map(mapProductDto);

            const remainingOfferings = action.productOfferings.value
                .filter(p => {
                    const relatedProducts = p.relatedProducts.split(",").filter(rp => rp != null && rp.trim().length > 0);
                    return relatedProducts.find(rp => activeProducts.find(ap => ap.name.toLowerCase() === rp.toLowerCase()) !== undefined) === undefined;
                })
                .map(p => {
                    return {
                        productName: p.productName,
                        shortDescription: p.shortDescription,
                        isNew: p.isNew === true,
                        icon: p.icon,
                        url: p.learnMoreUrl
                    };
                });

            return {
                ...state,
                    status: "ProductsFetched",
                    activeProducts: _.orderBy(activeProducts, "displayName"),
                    remainingOfferings: _.orderBy(remainingOfferings, ["isNew", "productName"], ["desc", "asc"])
            };
        }

        case actionTypes.GET_USER_INFO_SUCCESS: {
            return {...state, userInfo: action.userInfo};
        }
        case actionTypes.GET_USER_INFO_FAIL:
            return state;

        case actionTypes.GET_PRODUCTS_FAIL:
            return state;

        case actionTypes.GET_TRANSFER_SUCCESS: {
            let xinfo = action.transferInfo;
            xinfo.status = 'TransferInfoReceived';
            return {...state, transferInfo: xinfo};
        }

        case actionTypes.LOG_IN_TO_DASHBOARD_SUCCESS: {
            let newTransferInfo = Object.assign({}, state.transferInfo);
            newTransferInfo.status = 'LoggedInToDestination';

            return {...state, transferInfo: newTransferInfo};
        }

        case actionTypes.LOG_IN_TO_DESTINATION_PLATFORM_CLIENT_SUCCESS: {
            let newTransferInfo = Object.assign({}, state.transferInfo);
            newTransferInfo.status = 'LoggedInToDestination';

            let redirectUrl = action.transferInfo.redirectUrl;

            if (redirectUrl) {
                if (redirectUrl.indexOf('?') !== -1) { // already has query string
                    newTransferInfo.destinationUrl = redirectUrl;
                } else { // no query string
                    newTransferInfo.destinationUrl = redirectUrl + '?ReturnToUrl=' + encodeURIComponent(newTransferInfo.destinationUrl);
                }
            }

            return {...state, transferInfo: newTransferInfo};
        }

        case actionTypes.TRANSFER_SUCCESS: {
            let transfer = {
                transferUrl: action.transferUrl,
                status: 'TransferSuccess'
                };
            return  {...state, transfer: transfer};
        }

        case actionTypes.TRANSFER_FAIL:
            return state;

        case actionTypes.GET_USERCUSTOMERS_SUCCESS: {
            const userCustomers = action.result.map(c => {
                return {
                    customerName: c.name,
                    customerId: c.customerId
                };
            });
            return {...state, userCustomers: _.orderBy(userCustomers, "customerName")};
        }

        case actionTypes.GET_USERCUSTOMERS_FAIL:
            return state;

        case actionTypes.GET_USERPRODUCTS_SUCCESS: {
            const activeProducts = action.result.map(mapProductDto);

            return {
                ...state,
                status: "ProductsFetched",
                activeProducts: _.orderBy(activeProducts, "displayName")
            };
        }

        case actionTypes.GET_USERPRODUCTS_FAIL:
            return state;

        default:
            return state;
    }
}
