import * as actionTypes from '../../actions/actionTypes';
import initialState from '../initialState';

export default function forgotPasswordReducer(state = initialState.passwordReset, action) {

    switch (action.type) {
        case actionTypes.RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state, { status : 'PasswordResetSuccess', errors: []});
        case actionTypes.RESET_PASSWORD_FAIL: {
            return Object.assign({}, state, { status: 'PasswordResetFail', errors: action.error});
        }
        default:
            return state;
    }
}
