//Please try not to change the variable names.
//If you do, you must update Octopus Deploy so it replaces the variable name properly.
let cf = {
    authApiUrl: '#{AuthApiUrl}',
    northBaseUrl: '#{NorthBaseUrl}',
    customerAdminApiUrl: '#{CustomerAdminApiUrl}',
    clientId: '#{DefaultClientId}',
    appInsightInstrumentationKey: '#{AuthUIAppInsightInstrumentationKey}',
    supportInfo: {
        email: '#{NcontractsSupportEmail}',
        phone: '#{NcontractsSupportPhone}'
    },
    productTrackingUrl: "#{productTrackingUrl}",
    cdnUrl: "#{CDNUrl}",
    cookieName: {
        domain: "#{cookie-domain}",
        accessTokenCookieName: "#{cookie-access-token}",
        refreshTokenCookieName: "#{cookie-refresh-token}",
        userIdCookieName: "#{cookie-user-id}",
        tokenExpirationDateCookieName: "#{cookie-token-expiration-date}"
    },
    monitoringAgents: "#{MonitoringAgents}",
    forwardToHub: "#{ForwardToHub}"
};
export default cf;

